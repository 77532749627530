<template>
  <div>
    <div class="d-flex align-center justify-space-between mb-10 my-8">
      <!-- <v-btn icon rounded @click="$router.go(-1)">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dense v-bind="attrs" v-on="on">
              <v-icon size="28">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>Volver</span>
        </v-tooltip>
      </v-btn> -->
      <div></div>
      <h1 class="justify-center">Gestión de inventarios</h1>
      <div v-if="licen.Var29" class="d-flex">
        <v-btn
          color="primary"
          class="mx-2 rounded-lg text-capitalize"
          @click="btnSelectOptionMenu('reposicion')"
          >Reposición</v-btn
        >
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on" class="ml-2">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="btnSelectOptionMenu('toma')">
                <v-list-item-title>Tomar inventario</v-list-item-title>
              </v-list-item>
              <v-divider class="ml-3 mr-3"></v-divider>
              <v-list-item @click="btnSelectOptionMenu('oc')">
                <v-list-item-title>Orden de compra</v-list-item-title>
              </v-list-item>
              <v-divider class="ml-3 mr-3"></v-divider>

              <v-list-item @click="btnSelectOptionMenu('codebar')">
                <v-list-item-title>Código de barra</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div v-else></div>
    </div>
    <div v-if="licen.Var29">
      <div v-if="viewInventario">
        <!-- barra de estadistica (vista escritorio) -->
        <v-card
          class="white pa-5 my-3 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"
          elevation="1"
        >
          <v-row class="justify-center">
            <v-col>
              <p class="text-center">
                <strong>Inventarios</strong>
              </p>
              <h1 class="text-center h3">{{ statsInventarios.total }}</h1>
            </v-col>
            <v-col>
              <p class="text-center">
                <strong>Stock disponible</strong>
              </p>
              <h1 class="text-center h3">
                {{ statsInventarios.stockdisponible }}
              </h1>
            </v-col>
            <v-col>
              <p class="text-center">
                <strong>Sin stock</strong>
              </p>
              <h1 class="text-center h3">{{ statsInventarios.sinstock }}</h1>
            </v-col>
            <v-col>
              <p class="text-center">
                <strong>Stock negativo</strong>
              </p>
              <h1 class="text-center h3">
                {{ statsInventarios.stocknegativo }}
              </h1>
            </v-col>
            <v-col>
              <p class="text-center">
                <strong>Bajo stock</strong>
              </p>
              <h1 class="text-center h3">{{ statsInventarios.stockbajo }}</h1>
            </v-col>
          </v-row>
        </v-card>
        <!-- barra de estadística (vista móvil) -->
        <v-card
          class="white pa-3 my-3 d-flex d-sm-flex d-md-none d–lg-none d-xl-none"
          elevation="1"
        >
          <v-col>
            <v-row class="d-flex justify-space-between">
              <p>
                <strong>Inventarios:</strong>
              </p>
              <h2>{{ statsInventarios.total }}</h2>
            </v-row>
            <v-row class="d-flex justify-space-between">
              <p>
                <strong>Stock disponible:</strong>
              </p>
              <h2>
                {{ statsInventarios.stockdisponible }}
              </h2>
            </v-row>
            <v-row class="d-flex justify-space-between">
              <p>
                <strong>Sin stock:</strong>
              </p>
              <h2>{{ statsInventarios.sinstock }}</h2>
            </v-row>
            <v-row class="d-flex justify-space-between">
              <p>
                <strong>Stock negativo:</strong>
              </p>
              <h2>
                {{ statsInventarios.stocknegativo }}
              </h2>
            </v-row>
            <v-row class="d-flex justify-space-between">
              <p>
                <strong>Bajo stock:</strong>
              </p>
              <h2>{{ statsInventarios.stockbajo }}</h2>
            </v-row>
          </v-col>
        </v-card>
        <v-card class="pa-3 rounded-xl mt-5">
          <div class="ma-3">
            <div class="d-flex mt-7 flex-wrap">
              <h2 class="ml-10">Buscar en el inventario</h2>
              <v-spacer></v-spacer>
              <div class="d-flex" :class="isMobile ? 'mt-2' : 'ma-0'">
                <v-select
                  outlined
                  dense
                  class="mx-2"
                  :items="sedes"
                  item-text="Origen"
                  item-value="Origen"
                  label="Sucursal"
                  v-model="selectSucursal"
                ></v-select>
                <v-btn
                  color="primary"
                  outlined
                  class="text-capitalize rounded-lg"
                  @click="btnFiltrarInventarioBySucursal"
                  >Filtrar</v-btn
                >
              </div>
            </div>
            <v-card-text class="pa-0 mx-2 mt-2">
              <v-text-field
                outlined
                v-model="searchInventarios"
                label="Buscar por cualquier término"
              ></v-text-field>
              <v-data-table
                :loading="activeLoadingSearchAllInventarios"
                :headers="headersInventarios"
                :items="inventarios"
                :search="searchInventarios"
              >
                <template v-slot:item.action="{ item }">
                  <div class="actions pa-1">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="text-capitalize rounded-lg"
                          @click="btnVerMovimientoInventario(item)"
                          ><v-icon>mdi-check-circle-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver movimientos</span>
                    </v-tooltip>
                  </div>
                </template>
                <template v-slot:no-data>
                  <v-alert :value="true">
                    No hay resultados disponibles
                  </v-alert>
                </template>
              </v-data-table>
            </v-card-text>
          </div>
        </v-card>
      </div>
      <div v-if="viewMovimientoInventario">
        <v-card min-height="65vh" class="pa-3 rounded-xl my-3">
          <div class="ma-3">
            <div class="d-flex">
              <h2>
                Movimientos de inventario de {{ selectViewInventario.Nombre }}
              </h2>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    @click="btnCerrarViewInventario"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Cerrar</span>
              </v-tooltip>
            </div>
            <h3>
              Cantidad disponible del filtro {{ selectViewInventario.Saldos }}
            </h3>
            <div class="d-flex mt-3">
              <v-menu
                v-model="menuStartMovimiento"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDateMovimientosInventario"
                    label="Fecha de inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    class="mx-1"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="ES-es"
                  v-model="startDateMovimientosInventario"
                  @input="menuStartMovimiento = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                v-model="menuEndMovimiento"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDateMovimientosInventario"
                    label="Fecha de fin"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    class="mx-1"
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="ES-es"
                  v-model="endDateMovimientosInventario"
                  @input="menuEndMovimiento = false"
                ></v-date-picker>
              </v-menu>
              <v-select
                dense
                :items="sedes"
                item-value="Origen"
                item-text="Origen"
                v-model="selectSucursal"
                label="Sucursal"
                outlined
                class="mx-1"
              ></v-select>
              <v-btn
                class="mx-1"
                color="primary"
                @click="btnFilterMovimientoInventario"
                >Filtrar</v-btn
              >
            </div>
            <v-card-text> </v-card-text>
          </div>
          <div>
            <v-row>
              <v-col>
                <div class="my-3">
                  <h3 class="text-center">Ventas</h3>
                </div>
                <div class="d-flex justify-center my-3">
                  <h3>Ventas {{ statsInventarioVtaser.total }}</h3>
                  <v-divider class="mx-3" vertical></v-divider>
                  <h3>
                    Total cantidad {{ statsInventarioVtaser.totalCantidad }}
                  </h3>
                </div>
                <div>
                  <v-text-field
                    label="Buscar en ventas"
                    outlined
                    dense
                    v-model="searchInventariosVtaser"
                  ></v-text-field>
                  <v-data-table
                    :headers="headersInventariosVtaser"
                    :loading="activeLoadingSearchInventariosVtaser"
                    :items="inventariosVtaser"
                    :search="searchInventariosVtaser"
                  ></v-data-table>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <div class="my-3">
                  <h3 class="text-center">Compras</h3>
                </div>
                <div class="d-flex justify-center my-3">
                  <h3>Compras {{ statsInventariosCompras.total }}</h3>
                  <v-divider class="mx-3" vertical></v-divider>
                  <h3>
                    Total cantidad {{ statsInventariosCompras.totalCantidad }}
                  </h3>
                </div>
                <div>
                  <v-text-field
                    label="Buscar en compras"
                    outlined
                    dense
                    v-model="searchInventariosCompras"
                  ></v-text-field>
                  <v-data-table
                    :headers="headersInventariosCompras"
                    :loading="activeLoadingSearchAllComprasByInventario"
                    :items="inventariosCompras"
                    :search="searchInventariosCompras"
                  ></v-data-table>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <div v-if="viewReposicion">
        <v-card min-height="65vh" class="pa-3 rounded-xl">
          <div class="ma-3">
            <div class="d-flex">
              <h3 class="">Reposición de productos</h3>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    @click="btnCerrarViewInventario"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Cerrar</span>
              </v-tooltip>
            </div>
            <v-row>
              <v-col>
                <!-- titutlos y cierre -->
                <div class="d-flex" v-if="!activeSearchOcReposicion">
                  <h3 class="mt-1">Datos del documento de ingreso</h3>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    outlined
                    small
                    @click="activeSearchOcReposicion = true"
                    >Cargar desde OC</v-btn
                  >
                </div>
                <div v-else class="d-flex">
                  <h3 class="mt-1">Ordenes existentes</h3>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-3"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="activeSearchOcReposicion = false"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                    <span>Cerrar buscador de oc</span>
                  </v-tooltip>
                </div>
                <!-- nueva reposicion -->
                <div v-if="!activeSearchOcReposicion">
                  <div class="d-flex mt-2">
                    <v-menu
                      v-model="menuFechaCompra"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selectFechaCompra"
                          label="Fecha del documento"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          class="ma-1"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="ES-es"
                        v-model="selectFechaCompra"
                        @input="menuFechaCompra = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="d-flex my-1">
                    <v-select
                      outlined
                      v-model="selectTipodocCompra"
                      :items="tipoDocCompra"
                      dense
                      label="Tipo de documento"
                      class="mx-1"
                    ></v-select>
                    <v-text-field
                      outlined
                      class="mx-1"
                      v-model="selectNdocCompra"
                      dense
                      type="number"
                      label="N° doc"
                    ></v-text-field>
                  </div>
                  <!-- proveedor -->
                  <div>
                    <h4>Prooveedor</h4>
                    <v-autocomplete
                      class="my-2"
                      label="Proveedor"
                      :items="proveedores"
                      outlined
                      dense
                      item-text="Razon_social"
                      return-object
                      v-model="selectProveedor"
                    ></v-autocomplete>
                  </div>

                  <h4 class="my-3">Producto</h4>
                  <div>
                    <div>
                      <div class="d-flex">
                        <div>
                          <v-icon class="mx-2 mb-1 mt-1" size="30"
                            >mdi-barcode-scan</v-icon
                          >
                        </div>
                        <!-- buscar -->
                        <v-autocomplete
                          :items="produsInventario"
                          item-text="Despcod"
                          outlined
                          dense
                          label="Buscar productos"
                          :search-input.sync="searchProdus"
                          :loading="activeLoadingCargarProdu"
                          clearable
                          return-object
                          v-model="selectProdu"
                        >
                        </v-autocomplete>
                        <v-btn color="primary" class="mt-1 ml-2" @click="btnSearchProductByServiece"
                            @keyup.enter="btnSearchProductByServiece">Buscar</v-btn>
                      </div>
                      <!-- producto seleccionado -->
                    </div>
                    <v-card
                      color=""
                      outlined
                      elevation="0"
                      class="pa-0 mb-2 ma-2 rounded-xl"
                      v-if="selectProdu"
                    >
                      <div class="pa-2">
                        <div class="d-flex justify-center">
                          <h3 class="black--text my-2 mx-3">
                            {{ selectProdu.Descripcion }}
                          </h3>
                        </div>
                        <!-- precio , descuento y cantidad -->
                        <div
                          class="ml-3 d-flex align-start align-sm-start align-md-center align-lg-center justify-center mb-3 flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                        >
                          <div>
                            <h3 class="ma-1">Costo de la compra</h3>
                            <v-text-field
                              dense
                              outlined
                              v-model="selectCostoCompra"
                              type="number"
                              min="0"
                              label="Costo"
                            ></v-text-field>
                          </div>
                          <v-divider vertical class="mx-3 mt-2 mb-1">
                          </v-divider>
                          <div>
                            <h3 class="ma-1">Precio venta publico</h3>
                            <v-text-field
                              dense
                              outlined
                              type="number"
                              v-model="selectPvpCompra"
                              min="0"
                              label="Pvp"
                            ></v-text-field>
                          </div>
                          <v-divider vertical class="mx-3 mt-2 mb-1">
                          </v-divider>
                          <!-- cantidad a agregar -->
                          <div class="">
                            <h3 class="ma-1">Cantidad</h3>
                            <div class="d-flex">
                              <v-text-field
                                outlined
                                color="primary"
                                type="number"
                                min="1"
                                v-model="cantidadAgregarReposicion"
                                dense
                              ></v-text-field>
                              <v-btn
                                color="primary"
                                @click="btnAddProduCompra"
                                class="mx-2 text-capitalize"
                                >Agregar</v-btn
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                </div>
                <!-- buscador de oc para reposcion -->
                <div v-else>
                  <div class="text-center">Seleccione filtro de busqueda</div>
                  <div class="d-flex justify-center">
                    <v-radio-group v-model="selectTipoFiltroSearchOc" row>
                      <v-radio label="Fecha" value="fecha"></v-radio>
                      <v-radio label="Proveedor" value="proveedor"></v-radio>
                      <v-radio label="N° oc" value="ndoc"></v-radio>
                    </v-radio-group>
                  </div>
                  <!-- opciones de busqueda -->
                  <div class="d-flex justify-end">
                    <!-- opciones -->
                    <div>
                      <div
                        v-if="selectTipoFiltroSearchOc == 'fecha'"
                        class="d-flex"
                      >
                        <v-menu
                          v-model="selectMenuStartDateSearchOC"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="selectStartDateSearchOC"
                              label="Fecha de inicio"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            locale="ES-es"
                            v-model="selectStartDateSearchOC"
                            @input="selectMenuStartDateSearchOC = false"
                          ></v-date-picker>
                        </v-menu>
                        <v-menu
                          v-model="selectMenuEndDateSearchOC"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="selectEndDateSearchOC"
                              label="Fecha de fin"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              dense
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            locale="ES-es"
                            v-model="selectEndDateSearchOC"
                            @input="selectMenuEndDateSearchOC = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                      <div>
                        <div v-if="selectTipoFiltroSearchOc == 'proveedor'">
                          <v-autocomplete
                            label="Proveedor"
                            :items="proveedores"
                            outlined
                            dense
                            item-text="Razon_social"
                            return-object
                            v-model="selectProveedorOcSearch"
                          ></v-autocomplete>
                        </div>
                      </div>
                      <div>
                        <div v-if="selectTipoFiltroSearchOc == 'ndoc'">
                          <v-text-field
                            type="number"
                            outlined
                            v-model="selectNumeroSerachOc"
                            dense
                            min="0"
                            label="N° de oc"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                    <!-- boton buscar -->
                    <div v-if="selectTipoFiltroSearchOc">
                      <v-btn
                        color="primary"
                        class="mx-2"
                        @click="btnBuscarOcByFilter"
                        >Buscar</v-btn
                      >
                    </div>
                  </div>
                  <!-- tabla de busqeuda -->
                  <v-data-table
                    :loading="activeLoadingSearchOc"
                    :headers="headersOcSearch"
                    :items="selectOcsSearchSet"
                  >
                    <template v-slot:item.action="{ item, index }">
                      <div class="actions pa-1">
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              icon
                              v-bind="attrs"
                              v-on="on"
                              class="text-capitalize rounded-lg"
                              @click="btnSelectOcSearchReposicion(item.Numero)"
                              ><v-icon>mdi-check-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Seleccionar</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </v-data-table>
                  <v-dialog
                    v-model="activeDialogCargarProduOcReposicion"
                    scrollable
                    persistent
                    max-width="380px"
                  >
                    <v-card class="pa-6 rounded-xl">
                      <v-card-title>Productos de la OC Recibidos</v-card-title>
                      <v-divider></v-divider>

                      <v-card-text style="height: 300px">
                        <v-card
                          elevation="0"
                          class="my-1"
                          v-for="produ in selectOcsReposicion"
                          :key="produ.Id"
                        >
                          <v-row>
                            <v-col class="mt-2">{{ produ.Descripcion }}</v-col>
                            <v-col
                              ><v-text-field
                                type="number"
                                dense
                                outlined
                                label="Recibido"
                                v-model="produ.Cantidad"
                              ></v-text-field
                            ></v-col>
                          </v-row>
                          <v-divider class="my-1"></v-divider>
                        </v-card>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-btn
                          color="primary"
                          outlined
                          @click="btnCancelarCargaOCReposicion"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn color="primary" @click="btnCargarOCReposicion">
                          Cargar OC
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>

                <v-snackbar v-model="successCreateReposicionCompras">
                  Reposición realizada con éxito
                </v-snackbar>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col>
                <h3>Lista de productos a reponer</h3>
                <v-text-field
                  label="Buscar"
                  dense
                  v-model="searchCompras"
                  class="my-2"
                  outlined
                ></v-text-field>
                <v-data-table
                  :headers="headersCompras"
                  :items="selectCompras"
                  :search="searchCompras"
                  items-per-page="5"
                >
                  <template v-slot:item.action="{ item, index }">
                    <div class="actions pa-1">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="text-capitalize rounded-lg"
                            @click="btnDeleteCompraByIndex(index)"
                            ><v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
                <div class="d-flex my-2 justify-end">
                  <v-btn
                    color="primary"
                    :loading="activeLoadingCreateReposicionCompras"
                    @click="btnCreateReposicionCompra"
                    >Grabar reposición</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
      <div v-if="viewOC">
        <v-card min-height="65vh" class="pa-3 rounded-xl">
          <div class="ma-3">
            <!-- botón cerrar -->
            <div class="d-flex justify-end">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    @click="btnCerrarViewInventario"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Cerrar</span>
              </v-tooltip>
            </div>
            <!-- título de la tarjeta -->
            <div class="d-flex justify-center">
              <h2 class="mb-8">Órdenes de compra</h2>
            </div>
            <!-- datos de la oc y selección de productos -->
            <v-row>
              <!-- Columna "datos del nuevo documento" -->
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <div class="d-flex ml-3">
                  <v-row
                    v-if="!activeSearchOc"
                    class="my-2 d-flex justify-center"
                  >
                    <!-- subtítulo -->
                    <div>
                      <h3 class="mt-2">Datos del nuevo documento</h3>
                    </div>
                    <!-- Botón buscar o cerrar -->
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ml-3"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="activeSearchOc = true"
                          >
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </template>
                        <span>Buscar oc existentes</span>
                      </v-tooltip>
                    </div>
                  </v-row>
                  <div v-else class="d-flex my-2">
                    <h3 class="mt-2">Búsqueda de oc existentes</h3>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ml-3"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="activeSearchOc = false"
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Cerrar</span>
                    </v-tooltip>
                  </div>
                </div>
                <div>
                  <div v-if="!activeSearchOc">
                    <!-- Fecha de oc -->
                    <div class="d-flex mt-2">
                      <v-menu
                        v-model="menuFechaOC"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="selectFechaOC"
                            label="Fecha de la OC"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            class="ma-1"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="ES-es"
                          v-model="selectFechaOC"
                          @input="menuFechaOC = false"
                        ></v-date-picker>
                      </v-menu>
                      <v-menu
                        v-model="menuFechaVencimientoOC"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="selectFechaVencimientoOC"
                            label="Fecha de vencimiento"
                            prepend-icon="mdi-calendar"
                            readonly
                            outlined
                            dense
                            class="ma-1"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="ES-es"
                          v-model="selectFechaVencimientoOC"
                          @input="menuFechaVencimientoOC = false"
                        ></v-date-picker>
                      </v-menu>
                    </div>
                    <!-- plazo y forma -->
                    <div class="d-flex my-1">
                      <v-select
                        outlined
                        v-model="selectFormaPagoOC"
                        :items="tipoFormaPagoOC"
                        dense
                        label="Forma de pago"
                      ></v-select>
                      <v-select
                        outlined
                        v-model="selectPlazoOC"
                        :items="plazosOC"
                        dense
                        class="ml-2"
                        label="Plazo del pago"
                      ></v-select>
                    </div>
                    <!-- seleccionar proveedor oc -->
                    <div>
                      <h4>Prooveedor</h4>
                      <v-autocomplete
                        class="my-2"
                        label="Proveedor"
                        :items="proveedores"
                        outlined
                        dense
                        item-text="Razon_social"
                        return-object
                        v-model="selectProveedorOc"
                      ></v-autocomplete>
                    </div>
                    <!-- seleccionar produ oc -->
                    <h4 class="my-3">Producto</h4>
                    <div>
                      <!-- autocomplete para buscar -->
                      <div>
                        <div class="d-flex">
                          <div>
                            <v-icon class="mx-2 mb-1 mt-1" size="30"
                              >mdi-barcode-scan</v-icon
                            >
                          </div>
                          <!-- buscar -->
                          <v-autocomplete
                            :items="produsInventario"
                            item-text="Despcod"
                            outlined
                            dense
                            label="Buscar productos"
                            :search-input.sync="searchProdus"
                          :loading="activeLoadingCargarProdu"
                            clearable
                            return-object
                            v-model="selectProduOc"
                          >
                          </v-autocomplete>
                          <v-btn color="primary" class="mt-1 ml-2" @click="btnSearchProductByServiece"
                            @keyup.enter="btnSearchProductByServiece">Buscar</v-btn>
                        </div>
                        <!-- producto seleccionado -->
                      </div>
                      <!-- tabla con produ oc seleccionado -->
                      <v-card
                        color=""
                        outlined
                        elevation="0"
                        class="pa-2 mb-2 ma-2 rounded-xl"
                        v-if="selectProduOc"
                      >
                        <div class="pa-2">
                          <div class="d-flex justify-center">
                            <h3 class="black--text my-2 mx-3">
                              {{ selectProduOc.Descripcion }}
                            </h3>
                          </div>
                          <div>
                            <div
                              class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                            >
                              <v-text-field
                                outlined
                                label="Cantidad"
                                min="1"
                                v-model="selectCantidadProduOc"
                                type="number"
                                dense
                                class="ma-2"
                              >
                              </v-text-field>
                              <v-text-field
                                outlined
                                label="Costo"
                                v-model="selectProduOc.Costo"
                                readonly
                                dense
                                class="ma-2"
                              >
                              </v-text-field>
                              <v-text-field
                                outlined
                                label="Descuento %"
                                min="0"
                                max="100"
                                v-model="porcentajeProudOC"
                                type="number"
                                dense
                                class="ma-2"
                              >
                              </v-text-field>
                            </div>
                            <div
                              class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row"
                            >
                              <v-text-field
                                outlined
                                label="Subtotal"
                                readonly
                                v-model="calculateSubtotalProdu"
                                type="number"
                                dense
                                class="ma-2"
                              >
                              </v-text-field>
                              <v-text-field
                                outlined
                                label="IVA"
                                readonly
                                v-model="calculatIvaOCProdu"
                                type="number"
                                dense
                                class="ma-2"
                              >
                              </v-text-field>
                              <v-text-field
                                outlined
                                label="Total"
                                v-model="calculaTotalOcProdu"
                                readonly
                                dense
                                class="ma-2"
                              >
                              </v-text-field>
                            </div>
                            <div class="d-flex justify-end">
                              <v-btn
                                class="ma-1"
                                @click="btnAddProduOc"
                                color="primary"
                                >Agregar</v-btn
                              >
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <!-- Buscar en ocs existenes -->
                  <div v-else>
                    <div class="text-center">Seleccione filtro de busqueda</div>
                    <div class="d-flex justify-center">
                      <v-radio-group v-model="selectTipoFiltroSearchOc" row>
                        <v-radio label="Fecha" value="fecha"></v-radio>
                        <v-radio label="Proveedor" value="proveedor"></v-radio>
                        <v-radio label="N° oc" value="ndoc"></v-radio>
                      </v-radio-group>
                    </div>
                    <!-- opciones de busqueda -->
                    <div class="d-flex justify-end">
                      <!-- opciones -->
                      <div>
                        <div
                          v-if="selectTipoFiltroSearchOc == 'fecha'"
                          class="d-flex"
                        >
                          <v-menu
                            v-model="selectMenuStartDateSearchOC"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="selectStartDateSearchOC"
                                label="Fecha de inicio"
                                prepend-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              locale="ES-es"
                              v-model="selectStartDateSearchOC"
                              @input="selectMenuStartDateSearchOC = false"
                            ></v-date-picker>
                          </v-menu>
                          <v-menu
                            v-model="selectMenuEndDateSearchOC"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="selectEndDateSearchOC"
                                label="Fecha de fin"
                                prepend-icon="mdi-calendar"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              locale="ES-es"
                              v-model="selectEndDateSearchOC"
                              @input="selectMenuEndDateSearchOC = false"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                        <div>
                          <div v-if="selectTipoFiltroSearchOc == 'proveedor'">
                            <v-autocomplete
                              label="Proveedor"
                              :items="proveedores"
                              outlined
                              dense
                              item-text="Razon_social"
                              return-object
                              v-model="selectProveedorOcSearch"
                            ></v-autocomplete>
                          </div>
                        </div>
                        <div>
                          <div v-if="selectTipoFiltroSearchOc == 'ndoc'">
                            <v-text-field
                              type="number"
                              outlined
                              v-model="selectNumeroSerachOc"
                              dense
                              min="0"
                              label="N° de oc"
                            ></v-text-field>
                          </div>
                        </div>
                      </div>
                      <!-- boton buscar -->
                      <div v-if="selectTipoFiltroSearchOc">
                        <v-btn
                          color="primary"
                          class="mx-2"
                          @click="btnBuscarOcByFilter"
                          >Buscar</v-btn
                        >
                      </div>
                    </div>
                    <!-- tabla de busqeuda -->
                    <v-data-table
                      :loading="activeLoadingSearchOc"
                      :headers="headersOcSearch"
                      :items="selectOcsSearchSet"
                    >
                      <template v-slot:item.action="{ item, index }">
                        <div class="actions pa-1">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                icon
                                v-bind="attrs"
                                v-on="on"
                                class="text-capitalize rounded-lg"
                                @click="btnSelectOcSearch(item.Numero)"
                                ><v-icon>mdi-check-circle-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Seleccionar</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                  <!-- Generacion y descargar de documentos -->
                  <v-dialog
                    v-model="activeDialogCreateOc"
                    persistent
                    max-width="500"
                    class="pa-3"
                  >
                    <v-card class="pa-3 rounded-xl">
                      <v-card-title class="text-h5">
                        Orden de compra generada
                      </v-card-title>
                      <v-card-text>
                        <div>
                          <v-card class="my-2 pa-3" elevation="0" outlined>
                            <v-row>
                              <v-col class="d-flex"
                                ><v-icon>mdi-file-document-outline</v-icon>
                                <div
                                  class="ml-2 mt-2"
                                  v-if="selectOcs.length != 0"
                                >
                                  {{ selectOcs[0].Numero }}
                                </div></v-col
                              >
                              <v-col class="mt-2">Orden de compra</v-col>
                              <v-col>
                                <GeneratePdfOc :ocs="selectOcs" />
                              </v-col>
                            </v-row>
                          </v-card>
                        </div>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          outlined
                          @click="btnCloseDialogOc"
                        >
                          Cerrar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-col>
              <v-divider vertical class="mt-3 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex"></v-divider>
              <!-- Columna "Lista de productos de la OC" -->
              <v-col xs="12" sm="12" md="6" lg="6" xl="6">
                <!-- subtítulo -->
                <div class="d-flex justify-center my-5">
                  <h3>Lista de productos de la OC</h3>
                </div>
                <v-text-field
                  label="Buscar"
                  dense
                  v-model="searchOc"
                  class="my-2"
                  outlined
                  append-icon="mdi-magnify"
                ></v-text-field>
                <!-- tabla de productos de la oc -->
                <v-data-table
                  :search="searchOc"
                  items-per-page="5"
                  :headers="headersOc"
                  :items="selectOcs"
                >
                  <template v-slot:item.Total="{ item }">
                    <div>$ {{ item.Total | formatCurrency }}</div>
                  </template>
                  <template v-slot:item.Subtotal="{ item }">
                    <div>$ {{ item.Subtotal | formatCurrency }}</div>
                  </template>
                  <template v-slot:item.action="{ item, index }">
                    <div class="actions pa-1">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="text-capitalize rounded-lg"
                            @click="btnDeleteOCByIndex(index)"
                            ><v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <v-alert :value="true">
                      No hay resultados disponibles
                    </v-alert>
                  </template>
                </v-data-table>
                <!-- estado oc (vista escritorio) -->
                <v-card class="mt-5 d-none d-sm-none d-md-flex d-lg-flex d-xl-flex rounded-lg" elevation="0" outlined>
                  <v-col>
                    <div>
                      <h4 class="text-center">Productos</h4>
                      <h3 class="text-center">{{ selectOcs.length }}</h3>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      <h4 class="text-center">Unidades</h4>
                      <h3 class="text-center">{{ calculaUnidadesOcs }}</h3>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      <h4 class="text-center">IVA</h4>
                      <h3 class="text-center">
                        $ {{ calculaIvaOcs | formatCurrency }}
                      </h3>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      <h4 class="text-center">Total</h4>
                      <h3 class="text-center">
                        $ {{ calculaTotalOcs | formatCurrency }}
                      </h3>
                    </div>
                  </v-col>
                </v-card>
                <!-- estado oc (vista móvil) -->
                <v-card class="mt-5 d-flex d-sm-flex d-md-none d-lg-none d-xl-none" elevation="0" outlined>
                  <v-col>
                    <div>
                      <h4 class="text-center">Productos</h4>
                      <h3 class="text-center">{{ selectOcs.length }}</h3>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      <h4 class="text-center">Unidades</h4>
                      <h3 class="text-center">{{ calculaUnidadesOcs }}</h3>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      <h4 class="text-center">IVA</h4>
                      <h3 class="text-center">
                        $ {{ calculaIvaOcs | formatCurrency }}
                      </h3>
                    </div>
                  </v-col>
                  <v-col>
                    <div>
                      <h4 class="text-center">Total</h4>
                      <h3 class="text-center">
                        $ {{ calculaTotalOcs | formatCurrency }}
                      </h3>
                    </div>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-end mx-2 my-5">
               <!-- boton  oc -->
               <div class="d-flex my-2 justify-end">
                  <v-btn
                    v-if="!activeSearchOc"
                    class="my-3 text-capitalize"
                    color="primary"
                    :loading="activeLoadingCreteOc"
                    @click="btnCreateOc"
                    >Crear orden de compra</v-btn
                  >
                  <v-btn
                    v-else
                    color="primary"
                    @click="btnImprimirOcSearch"
                    class="my-3"
                    >Imprimir</v-btn
                  >
                </div>
            </v-row>
          </div>
        </v-card>
      </div>
      <div v-if="viewTomaInventario">
        <v-card min-height="65vh" class="pa-3 rounded-xl">
          <div class="ma-3">
            <!-- botón cerrar -->
            <v-row class="d-flex justify-end mt-2 mr-2 mb-3">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    @click="btnSelectOptionMenu('inventario')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Cerrar</span>
              </v-tooltip>
            </v-row>
            <!-- título de la tarjeta -->
            <div class="d-flex justify-center mb-7">
              <h3>Toma de inventario</h3>
            </div>
            <!-- barra de datos -->
            <div class="my-5 inven">
              <v-row>
                <v-col>
                  <p class="text-center">
                    <strong>Inventarios</strong>
                  </p>
                  <h1 class="text-center h3">{{ statsInventarios.total }}</h1>
                </v-col>
                <v-col>
                  <p class="text-center">
                    <strong>Inventariados</strong>
                  </p>
                  <h1 class="text-center h3">0</h1>
                </v-col>
                <v-col>
                  <p class="text-center">
                    <strong>Por inventarear</strong>
                  </p>
                  <h1 class="text-center h3">0</h1>
                </v-col>
              </v-row>
            </div>
            <!-- menu individal o por lotes -->
            <v-tabs v-model="tipoTomaInventario">
              <v-tab key="scanner" class="text-capitalize"
                >Individual con escanner</v-tab
              >
              <v-tab key="lotes" class="text-capitalize"
                >Lotes por importación de excel</v-tab
              >
            </v-tabs>
            <!-- contenido del tab -->
            <v-tabs-items v-model="tipoTomaInventario">
              <!-- individual -->
              <v-tab-item key="scanner">
                <div class="mt-4">
                  <!-- busqueda y escanner -->
                  <div class="d-flex search-products">
                    <!-- buscar -->
                    <v-autocomplete
                      :readonly="disabledCart"
                      item-text="Despcod"
                      outlined
                      prepend-inner-icon="mdi-barcode-scan"
                      dense
                      :items="inventariosSet"
                      v-model="selectProduTomaInventario"
                      label="Buscar inventarios"
                      clearable
                      return-object
                    >
                    </v-autocomplete>
                    <!-- escaner -->
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            icon
                            class="mx-4 mt-1"
                            @click="activeReaderCode = true"
                          >
                            <v-icon class="mx-2 mb-1" size="50"
                              >mdi-camera</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Usar la camara</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <div v-if="activeReaderCode">
                    <ReaderAllCode
                      origen="inventario"
                      @actualizarVariable="actualizarVariable"
                    />
                  </div>
                  <div v-if="selectProduTomaInventario">
                    <v-card outlined class="pa-6 my-3">
                      <div class="d-flex">
                        <h2 class="mt-5">
                          {{ selectProduTomaInventario.Nombre }}
                        </h2>
                        <v-text-field
                          class="mx-3"
                          outlined
                          v-model="selectProduNuevaCantidaTomaInventario"
                          :min="0"
                          type="number"
                          label="N° Cantidad actual"
                        >
                        </v-text-field>
                        <v-btn
                          @click="btnActualizarIndividual"
                          :loading="activeLoadingCreateImportInventario"
                          color="primary"
                          class="mt-3"
                          >Grabar</v-btn
                        >
                      </div>
                    </v-card>
                    <v-data-table
                      :loading="activeLoadingSearchAllInventarios"
                      :headers="headersInventariosSet"
                      :items="inventariosSet"
                    >
                      <template v-slot:item.action="{ item }">
                        <div class="actions pa-1">
                          <v-chip> Pendiente</v-chip>
                        </div>
                      </template>
                      <template v-slot:no-data>
                        <v-alert :value="true">
                          No hay resultados disponibles
                        </v-alert>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </v-tab-item>
              <!-- lotes -->
              <v-tab-item key="lotes">
                <!-- filtrar por familia -->
                <div class="d-flex justify-end my-4 flex-wrap">
                  <v-spacer></v-spacer>
                  <v-autocomplete
                    outlined
                    dense
                    :items="familiasInventario"
                    v-model="selectFilterFamiliaExport"
                    label="Filtrar por familia"
                  ></v-autocomplete>
                  <v-btn
                    :class="isMobile ? 'mt-2' : 'mx-2'"
                    color="primary"
                    outlined
                    @click="btnExportExcelInventario"
                  >
                    <div class="d-flex">
                      <v-icon>mdi-microsoft-excel</v-icon>
                      <div class="mt-1 text-capitalize">Descargar excel</div>
                    </div>
                  </v-btn>
                </div>
                <v-file-input
                  label="Agregar archivo formato csv, xlsx"
                  outlined
                  counter
                  multiple
                  v-model="files"
                  :show-size="1000"
                  @change="importFile"
                  class="border-edit ma-1"
                  height="220px"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-avatar tile size="200" class="d-flex flex-column">
                      <v-icon size="100">mdi-microsoft-excel</v-icon>
                      <v-chip color="primary" dark label small>
                        {{ text }}
                      </v-chip>
                    </v-avatar>
                  </template>
                </v-file-input>
                <v-alert type="info" class="my-2" color="primary" outlined
                  >Al realizar la actualización por importación desde un
                  archivo, debe tener el mismo formato del archivo de descarga y
                  considere los siguientes puntos: <br />
                  No puede modificar la estructura del archivo <br />
                  No puede cambiar los códigos de los productos, esto generará
                  un error que impide que el archivo sea considerado.</v-alert
                >
                <div class="d-flex my-4 flex-wrap">
                  <v-text-field
                    v-model="searchInventariosImport"
                    label="Buscar en importados"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="activeLoadingCreateImportInventario"
                    color="primary"
                    class="text-capitalize"
                    @click="btnActualizarPorArchivo"
                    >Actualizar desde archivo</v-btn
                  >
                </div>
                <v-row class="my-2 d-flex justify-center">
                  <v-col
                    ><h2 class="text-center">Total</h2>
                    <h2 class="text-center mt-1">
                      {{ statsInventariosImport.total }}
                    </h2>
                  </v-col>
                  <v-col
                    ><h2 class="text-center">Válido</h2>
                    <h2 class="text-center mt-1">
                      {{ statsInventariosImport.valido }}
                    </h2>
                  </v-col>
                  <v-col
                    ><h2 class="text-center">Inválido</h2>
                    <h2 class="text-center mt-1">
                      {{ statsInventariosImport.invalido }}
                    </h2>
                  </v-col>
                </v-row>
                <!-- Alerta desplegada cuando los productos importados tienen errores -->
                <v-alert
                  v-if="statsInventariosImport.invalido != 0"
                  color="red"
                  class="my-2"
                  type="info"
                  outlined
                  >Existen productos con errores, estos no serán
                  actualizados</v-alert
                >
                <!-- Tabla informativa -->
                <v-data-table
                  :items="inventariosImport"
                  :search="searchInventariosImport"
                  :headers="headersInventariosImport"
                >
                  <template v-slot:item.Estado="{ item }">
                    <div>
                      <v-chip
                        :color="item.Estado == 'Valido' ? 'primary' : 'red'"
                        outlined
                        >{{ item.Estado }}</v-chip
                      >
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <v-alert :value="true">
                      No hay resultados disponibles
                    </v-alert>
                  </template>
                </v-data-table>
                <v-snackbar
                  v-model="successCreateTomaInventario"
                  color="primary"
                  >Inventario actualizado con éxito</v-snackbar
                >
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </div>
      <div v-if="viewCodigobarra">
        <v-card min-height="65vh" class="pa-3 rounded-xl">
          <!-- Botón cerrar -->
          <v-row class="d-flex justify-end ma-3">
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="primary"
                    @click="btnSelectOptionMenu('inventario')"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>Cerrar</span>
              </v-tooltip>
          </v-row>
          <div class="ma-3 d-flex justify-center">
            <h3 class="">Generar código de barra</h3>
          </div>
          <div>
            <div class="my-2">
              <h4 class="my-2 ml-3">
                Ingresar código a generar o buscar en productos
              </h4>
              <div class="d-flex">
                <v-text-field
                  outlined
                  dense
                  class="mx-2"
                  v-model="barcodeValue"
                  label="Código"
                ></v-text-field>
                <v-autocomplete
                  outlined
                  class="mx-2"
                  :items="produs"
                  v-model="barcodeValue"
                  item-text="Despcod"
                  item-value="Codigo"
                  dense
                  label="Productos"
                ></v-autocomplete>
              </div>
              <v-row class="d-flex">
                <!-- ajustes de impresion -->
                <v-col>
                  <h4 class="my-2 ml-3">Ajustes de la impresión</h4>
                  <div>
                    <!-- <div class="d-flex">
                      <v-text-field
                        outlined
                        dense
                        class="ma-1"
                        type="number"
                        v-model="altoCodigoGenerar"
                        min="100"
                        label="Alto"
                      ></v-text-field>
                    </div> -->
                    <v-text-field
                      outlined
                      dense
                      class="mx-2"
                      v-model="cantidadCodigoGenerar"
                      type="number"
                      min="1"
                      label="Cantidad de códigos a generar"
                    ></v-text-field>
                    <v-radio-group v-model="tipoPapelCodigoBarra" mandatory row class="mx-2">
                      <v-radio label="Carta" value="letter"></v-radio>
                      <v-radio label="Oficio" value="a4"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
                <!-- Código de barras -->
                <v-col class="d-flex flex-column align-center justify-center">
                  <vue-barcode
                    :value="barcodeValue"
                    :options="barcodeOptions"
                  />
                  <GeneratePdfCodigoBarra
                    :dataCodigo="{
                      barcodeValue: barcodeValue,
                      barcodeOptions: generateBarcodeOptions,
                      cantidad: cantidadCodigoGenerar,
                      tipopapel: tipoPapelCodigoBarra,
                    }"
                  />
                </v-col>
              </v-row>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div v-else class="d-flex justify-center pa-3 ma-3">
      <div class="d-flex flex-column">
        <v-avatar tile size="300" class="d-flex justify-center">
          <v-img contain sizes="200" src="/icons/notselect.svg"></v-img>
        </v-avatar>
        <div class="d-flex justify-center">
          <h3 class="mt-3 ml-3">
            No tiene los permisos necesarios para <br />
            acceder a los datos
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ReaderAllCode from "../../../components/ReaderAllCode.vue";
import GeneratePdfOc from "../components/GeneratePdfOc.vue";
import VueBarcode from "vue-barcode";
import GeneratePdfCodigoBarra from "../components/GeneratePdfCodigoBarra.vue";
export default {
  components: {
    GeneratePdfOc,
    ReaderAllCode,
    VueBarcode,
    GeneratePdfCodigoBarra,
  },
  data() {
    return {
      searchProdus:"",
      isMobile: false,
      selectViewInventario: "",
      searchInventarios: "",
      searchInventariosVtaser: "",
      searchInventariosCompras: "",
      menuStartMovimiento: false,
      selectSucursal: "",
      menuEndMovimiento: false,
      headersInventarios: [
        { text: "Código", value: "Cod" },
        { text: "Producto o servicio", value: "Nombre" },
        { text: "Familia", value: "Familia" },
        { text: "Ingresos", value: "Ingresos" },
        { text: "Salidas", value: "Ventas" },
        { text: "Stock actual", value: "Saldos" },
        { text: "Ver movimientos", value: "action" },
      ],
      headersInventariosSet: [
        { text: "Código", value: "Cod" },
        { text: "Producto o servicio", value: "Nombre" },
        { text: "Familia", value: "Familia" },
        { text: "Stock actual", value: "Saldos" },
        { text: "Estado", value: "action" },
      ],
      headersInventariosImport: [
        { text: "Código", value: "Cod" },
        { text: "Producto o servicio", value: "Nombre" },
        { text: "Nueva cantidad", value: "Cantidad" },
        { text: "Verificación", value: "Estado" },
      ],
      headersInventariosVtaser: [
        { text: "Fecha", value: "Fecha" },
        { text: "Cliente", value: "Nombre" },
        { text: "Tipodoc", value: "Tipo" },
        { text: "N° doc", value: "Bnumero" },
        { text: "Cantidad", value: "Cantidad" },
      ],
      headersInventariosCompras: [
        { text: "Fecha", value: "Fecha" },
        { text: "Tipodoc", value: "Tipo" },
        { text: "N° doc", value: "Guia" },
        { text: "Cantidad", value: "Entrada" },
        { text: "Proovedor", value: "Nomprov" },
      ],
      inventariosVtaser: [],
      inventariosCompras: [],
      statsInventarios: {
        total: 0,
        sinstock: 0,
        stockbajo: 0,
        stocknegativo: 0,
        stockdisponible: 0,
      },
      statsInventarioVtaser: {
        total: 0,
        totalCantidad: 0,
      },
      selectFilterFamiliaExport: "",
      statsInventariosCompras: {
        total: 0,
        totalCantidad: 0,
      },
      startDateMovimientosInventario: "",
      endDateMovimientosInventario: "",
      viewReposicion: false,
      viewInventario: true,
      viewMovimientoInventario: false,
      viewOC: false,
      viewTomaInventario: false,
      viewCodigobarra: false,
      selectProdu: "",
      selectCompras: [],
      headersCompras: [
        { text: "Producto ", value: "Nombre" },
        { text: "Cantidad", value: "Entrada" },
        { text: "Eliminar", value: "action" },
      ],
      headersOc: [
        { text: "Producto ", value: "Descripcion" },
        { text: "Cantidad", value: "Cantidad" },
        { text: "Subtotal", value: "Subtotal" },
        { text: "Total", value: "Total" },
        { text: "Eliminar", value: "action" },
      ],
      headersOcSearch: [
        { text: "Proveedor ", value: "Nombre" },
        { text: "Numero", value: "Numero" },
        { text: "Fecha", value: "Fecha" },
        { text: "Ver", value: "action" },
      ],
      cantidadAgregarReposicion: "1",
      selectCostoCompra: "0",
      selectPvpCompra: "0",
      selectNdocCompra: "",
      selectFechaCompra: "",
      selectTipodocCompra: "",
      selectProveedor: "",
      menuFechaCompra: "",
      tipoDocCompra: [
        "Guia interna",
        "Factura",
        "N de credito",
        "Toma de inventario",
        "Guia de despacho",
        "Consumo interno",
        "Merma",
        "Orden de compra",
      ],
      searchCompras: "",
      activeLoadingCreateReposicionCompras: false,
      produsInventario: [],
      selectFechaOC: "",
      menuFechaOC: false,
      selectFechaVencimientoOC: "",
      menuFechaVencimientoOC: false,
      selectFormaPagoOC: "",
      tipoFormaPagoOC: [
        "Efectivo",
        "Transferencia",
        "Credito",
        "Deposito",
        "Canje",
        "Convenio",
        "Tarjeta",
      ],
      selecescan: "",
      plazosOC: [
        "Al Dia",
        "30 Dias",
        "45 Dias",
        "60 Dias",
        "90 Dias",
        "120 Dias",
        "180 Dias",
      ],
      selectPlazoOC: "",
      selectProduOc: "",
      selectProveedorOc: "",
      searchOc: "",
      selectOcs: [],
      selectCantidadProduOc: "1",
      porcentajeProudOC: "0",
      activeLoadingCreteOc: false,
      activeDialogCreateOc: false,
      activeSearchOc: false,
      selectTipoFiltroSearchOc: "",
      selectProveedorOcSearch: "",
      selectStartDateSearchOC: "",
      selectMenuStartDateSearchOC: false,
      selectEndDateSearchOC: "",
      selectMenuEndDateSearchOC: false,
      selectNumeroSerachOc: "",
      selectOcsSearch: [],
      selectOcsSearchSet: [],
      activeSearchOcReposicion: false,
      selectOcsReposicion: [],
      activeDialogCargarProduOcReposicion: false,
      tipoTomaInventario: null,
      inventariosSet: [],
      selectProduTomaInventario: "",
      files: [],
      inventariosImport: [],
      dataDocs: [],
      readers: [],
      searchInventariosImport: [],
      activeReaderCode: false,
      familiasInventario: [],
      inventariosFilter: [],
      statsInventariosImport: {
        total: 0,
        valido: 0,
        invalido: 0,
        errores: [],
      },
      activeLoadingCreateImportInventario: false,
      selectProduNuevaCantidaTomaInventario: "",
      // bardcode
      barcodeValue: "1234567890", // Valor del código de barras
      barcodeOptions: {
        format: "CODE128", // Formato del código de barras (puedes elegir otros formatos también)
        displayValue: true, // Mostrar el valor del código de barras debajo del mismo
        height: 100, // Altura del código de barras
      },
      generateBarcodeOptions: {
        format: "CODE128", // Formato del código de barras (puedes elegir otros formatos también)
        displayValue: true, // Mostrar el valor del código de barras debajo del mismo
        height: 100,
      },
      altoCodigoGenerar: "",
      cantidadCodigoGenerar: 1,
      activeShowGenerarCodigo: false,
      tipoPapelCodigoBarra: "",
    };
  },
  watch: {
    produs: function (val) {
      this.setProdus();
    },
    inventarios: function (val) {
      if (val.length > 0) {
        this.setStatsInventarios();
        this.setInventarios();
      }
    },
    inventariosCompras: function (val) {
      if (val.length > 0) {
        this.setStatsInventariosCompras();
      }
    },
    inventariosVtaser: function (val) {
      if (val.length > 0) {
        this.setStatsInventariosVtaser();
      }
    },
    selectProdu: function (val) {
      if (val.Id) {
        this.selectCostoCompra = val.Costo;
        this.selectPvpCompra = val.Vtadeta;
      }
    },
    successCreateReposicionCompras: function (val) {
      if (val) {
        this.activeLoadingCreateReposicionCompras = false;
        this.selectProveedor = "";
        this.selectCostoCompra = "";
        this.selectNdocCompra = "";
        this.selectTipodocCompra = "";
        this.selectCompras = [];
        this.selectFechaCompra = "";
      }
    },
    successCreateOc: function (val) {
      this.activeLoadingCreteOc = false;
    },
    selectTipoFiltroSearchOc: function (val) {
      if (val) {
        this.selectOcsSearch = [];
      }
    },
    selectFilterFamiliaExport: function (val) {
      if (val) {
        this.inventariosFilter = [];
        this.inventarios.forEach((element) => {
          if (element.Familia == val) {
            this.inventariosFilter.push(element);
          }
        });
      }
    },
    successCreateTomaInventario: function (val) {
      if (val) {
        this.activeLoadingCreateImportInventario = false;
        this.inventariosImport = [];
        this.statsInventariosImport = {
          total: 0,
          valido: 0,
          invalido: 0,
          errores: [],
        };
      }
    },
    cantidadCodigoGenerar: function (val) {
      if (val) {
        this.cantidadCodigoGenerar = parseInt(val);
      }
    },
    altoCodigoGenerar: function (val) {
      if (val) {
        this.altoCodigoGenerar = parseInt(this.altoCodigoGenerar);
        this.generateBarcodeOptions.height = this.altoCodigoGenerar;
      }
    },
  },
  computed: {
    ...mapState("auth-module", ["user", "sedes", "licen"]),
    ...mapState("sales-module", [
      "inventarios",
      "activeLoadingSearchAllInventarios",
      "activeLoadingSearchInventariosVtaser",
      "activeLoadingSearchAllComprasByInventario",
      "proveedores",
      "successCreateReposicionCompras",
      "successCreateOc",
      "activeLoadingSearchOc",
      "successCreateTomaInventario",
    ]),
    ...mapState("medical-module", ["produs", "activeLoadingCargarProdu"]),
    checkMobile() {
      return (this.isMobile = window.innerWidth <= 500); // Define aquí el ancho máximo para considerar un dispositivo móvil
    },
    calculateSubtotalProdu() {
      if (this.selectProduOc.Id) {
        let total =
          parseInt(this.selectCantidadProduOc) * this.selectProduOc.Costo;
        if (this.porcentajeProudOC != "0") {
          total =
            parseInt(this.selectCantidadProduOc) * this.selectProduOc.Costo -
            parseInt(this.selectCantidadProduOc) *
              this.selectProduOc.Costo *
              (parseInt(this.porcentajeProudOC) / 100);
        }
        return total;
      } else {
        return "0";
      }
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.checkMobile); // Elimina el listener al destruir el componente
    },
    calculatIvaOCProdu() {
      if (this.selectProduOc.Id) {
        let total =
          parseInt(this.selectCantidadProduOc) *
          parseInt(this.selectProduOc.Costo);
        if (this.porcentajeProudOC != "0") {
          total =
            parseInt(this.selectCantidadProduOc) *
              parseInt(this.selectProduOc.Costo) -
            parseInt(this.selectCantidadProduOc) *
              parseInt(this.selectProduOc.Costo) *
              (parseInt(this.porcentajeProudOC) / 100);
        }
        let tax = Math.round(total * 1.19) - total;
        return tax.toString();
      }
    },
    calculaTotalOcProdu() {
      if (this.selectProduOc.Id) {
        let total =
          parseInt(this.selectCantidadProduOc) *
          parseInt(this.selectProduOc.Costo);
        if (this.porcentajeProudOC != "0") {
          total =
            parseInt(this.selectCantidadProduOc) *
              parseInt(this.selectProduOc.Costo) -
            parseInt(this.selectCantidadProduOc) *
              parseInt(this.selectProduOc.Costo) *
              (parseInt(this.porcentajeProudOC) / 100);
        }
        let iva = Math.round(total * 1.19) - total;
        let totalcalculate = total + iva;
        return totalcalculate.toString();
      }
    },
    calculaUnidadesOcs() {
      let total = 0;
      this.selectOcs.forEach((element) => {
        total += element.Cantidad;
      });
      return total;
    },
    calculaIvaOcs() {
      let total = 0;
      this.selectOcs.forEach((element) => {
        total += element.Impuesto;
      });
      return total;
    },
    calculaTotalOcs() {
      let total = 0;
      this.selectOcs.forEach((element) => {
        total += element.Total;
      });
      return total;
    },
  },
  methods: {
    ...mapActions("sales-module", [
      "getAllInventariosByVtaser",
      "getAllInventarios",
      "getAllComprasByInventario",
      "postReposicionCompras",
      "postOcs",
      "getOcsByFilter",
      "postActualizacionTomaInventario",
      "postActualizacionTomaInventarioIndividual",
    ]),
    ...mapActions("medical-module", ["getProdusByNameOrCode"]),
    actualizarVariable(code) {
      this.selectProduTomaInventario = this.inventarios.find(
        (item) => item.Cod == code
      );
      if (!this.selectProduTomaInventario.Id) {
        alert("Codigo no encontrado");
        return;
      }
    },
    setProdus() {
      this.produsInventario = [];
      this.produs.forEach((element) => {
        if (element.Afectainv == "SI") {
          element.Despcod = element.Descripcion + " / " + element.Codigo;
          this.produsInventario.push(element);
        }
      });
    },
    setInventarios() {
      this.inventariosSet = [];
      this.inventariosFilter = [];
      this.inventarios.forEach((element) => {
        this.inventariosFilter.push(element);
        element.Despcod = element.Nombre + " / " + element.Cod;
        this.inventariosSet.push(element);
      });
      this.familiasInventario = [];
      this.inventarios.forEach((element) => {
        let index = -1;
        index = this.familiasInventario.findIndex(
          (item) => item.Familia == element
        );
        if (index == -1) {
          this.familiasInventario.push(element.Familia);
        }
      });
    },
    async btnVerMovimientoInventario(inventario) {
      if (!this.selectSucursal) {
        alert("Debe seleccionar la sucursal");
        return;
      }
      let today = this.setDateNow().toISOString().substring(0, 10);
      const mes = parseInt(today.substring(6, 8));
      const cantidadDias = this.obtenerCantidadDias(mes);

      this.startDateMovimientosInventario = today.substring(0, 8) + "01";
      this.endDateMovimientosInventario = today.substring(0, 8) + cantidadDias;
      this.inventariosVtaser = await this.getAllInventariosByVtaser({
        cod: inventario.Cod,
        start_date: today.substring(0, 8) + "01",
        end_date: today.substring(0, 8) + cantidadDias,
        sucursal: this.selectSucursal,
      });
      this.btnSelectOptionMenu("movimientoInventario");
      this.selectViewInventario = inventario;

      this.inventariosCompras = await this.getAllComprasByInventario({
        cod: inventario.Cod,
        start_date: today.substring(0, 8) + "01",
        end_date: today.substring(0, 8) + cantidadDias,
        sucursal: this.selectSucursal,
      });
    },
    async btnFilterMovimientoInventario() {
      if (!this.selectViewInventario.Cod) {
        alert("Debe seleccionar el producto o servicio");
        return;
      }
      if (!this.selectSucursal) {
        alert("Debe seleccionar la sucursal");
        return;
      }
      if (!this.startDateMovimientosInventario) {
        alert("Debe seleccionar la fecha de inicio");
        return;
      }
      if (!this.endDateMovimientosInventario) {
        alert("Debe seleccionar la fecha de fin");
        return;
      }
      this.inventariosVtaser = await this.getAllInventariosByVtaser({
        cod: this.selectViewInventario.Cod,
        start_date: this.startDateMovimientosInventario,
        end_date: this.endDateMovimientosInventario,
        sucursal: this.selectSucursal,
      });

      this.inventariosCompras = await this.getAllComprasByInventario({
        cod: this.selectViewInventario.Cod,
        start_date: this.startDateMovimientosInventario,
        end_date: this.endDateMovimientosInventario,
        sucursal: this.selectSucursal,
      });
    },
    btnCerrarViewInventario() {
      this.viewInventario = false;
      this.selectViewInventario = "";
      this.statsInventariosCompras = {
        total: 0,
        totalCantidad: 0,
      };
      this.statsInventarioVtaser = {
        total: 0,
        totalCantidad: 0,
      };
      this.inventariosCompras = [];
      this.inventariosVtaser = [];
      this.btnSelectOptionMenu("inventario");
    },
    setStatsInventariosCompras() {
      this.statsInventariosCompras = {
        total: 0,
        totalCantidad: 0,
      };
      this.inventariosCompras.forEach((element) => {
        this.statsInventariosCompras.totalCantidad += element.Entrada;
        this.statsInventariosCompras.total += 1;
      });
    },
    setStatsInventariosVtaser() {
      this.statsInventarioVtaser = {
        total: 0,
        totalCantidad: 0,
      };
      this.inventariosVtaser.forEach((element) => {
        this.statsInventarioVtaser.totalCantidad += element.Cantidad;
        this.statsInventarioVtaser.total += 1;
      });
    },
    setStatsInventarios() {
      this.statsInventarios = {
        total: 0,
        sinstock: 0,
        stockbajo: 0,
        stocknegativo: 0,
        stockdisponible: 0,
      };
      this.inventarios.forEach((element) => {
        if (element.Saldos > 0) {
          this.statsInventarios.stockdisponible += 1;
        }
        if (element.Saldos < 0) {
          this.statsInventarios.stocknegativo += 1;
        }
        if (element.Saldos > 0 && element.Saldos < 6) {
          this.statsInventarios.stockbajo += 1;
        }
        if (element.Saldos == 0) {
          this.statsInventarios.sinstock += 1;
        }
        this.statsInventarios.total += 1;
      });
    },
    setDateNow() {
      var currentDateObj = new Date();
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
    obtenerCantidadDias(mes) {
      const mesesCon31Dias = [1, 3, 5, 7, 8, 10, 12];
      const mesesCon30Dias = [4, 6, 9, 11];

      if (mes === 2) {
        return 28; // En este caso, asumiremos que el año no es bisiesto
      } else if (mesesCon31Dias.includes(mes)) {
        return 31;
      } else if (mesesCon30Dias.includes(mes)) {
        return 30;
      } else {
        return -1; // Si el mes no es válido, se devuelve -1
      }
    },
    btnFiltrarInventarioBySucursal() {
      if (!this.selectSucursal) {
        alert("Debe seleccionar la sucursal para filtrar");
        return;
      }
      this.getAllInventarios({ sucursal: this.selectSucursal });
    },
    btnSelectOptionMenu(option) {
      switch (option) {
        case "reposicion":
          //validad el menu
          this.viewInventario = false;
          this.viewOC = false;
          this.viewMovimientoInventario = false;
          this.viewTomaInventario = false;
          this.viewCodigobarra = false;
          this.viewReposicion = true;
          break;
        case "movimientoInventario":
          //validad el menu
          this.viewInventario = false;
          this.viewReposicion = false;
          this.viewOC = false;
          this.viewTomaInventario = false;
          this.viewCodigobarra = false;
          this.viewMovimientoInventario = true;
          break;
        case "inventario":
          //validad el menu
          this.viewReposicion = false;
          this.viewMovimientoInventario = false;
          this.viewOC = false;
          this.viewTomaInventario = false;
          this.viewInventario = true;
          this.viewCodigobarra = false;

          break;
        case "oc":
          this.viewInventario = false;
          this.viewMovimientoInventario = false;
          this.viewReposicion = false;
          this.viewTomaInventario = false;
          this.viewCodigobarra = false;
          this.viewOC = true;
          break;
        case "toma":
          this.viewInventario = false;
          this.viewMovimientoInventario = false;
          this.viewReposicion = false;
          this.viewOC = false;
          this.viewCodigobarra = false;
          this.viewTomaInventario = true;
          break;
        case "codebar":
          this.viewInventario = false;
          this.viewMovimientoInventario = false;
          this.viewReposicion = false;
          this.viewOC = false;
          this.viewTomaInventario = false;
          this.viewCodigobarra = true;
          break;
      }
    },
    btnAddProduCompra() {
      if (!this.selectProdu.Id) {
        alert("No hay ningun producto seleccionado");
        return;
      }
      if (!this.selectTipodocCompra) {
        alert("Debe seleccionar un tipo de documento");
        return;
      }
      if (!this.selectNdocCompra) {
        alert("Debe seleccionar un numero de documento");
        return;
      }
      if (!this.selectProveedor.Id) {
        alert("Debe seleccionar un proveedor");
        return;
      }
      let compra = {
        Guia: "",
        Cod: "",
        Nombre: "",
        Entrada: 0,
        Salida: 0,
        Glosa: "",
        Fecha: "",
        Reg: "0",
        Rutpro: "",
        Nomprov: "",
        Des: 0,
        Neto: 0,
        Iva: 0,
        Total: 0,
        Tipo: "",
        Tipodte: 0,
        Cuenta: "0",
        Vencimiento: "",
        Id: 0,
        Sucursal: "",
        Ivacomun: 0,
        Fechatributacion: "",
        Familia: "n",
      };
      compra.Cod = this.selectProdu.Codigo;
      compra.Guia = this.selectNdocCompra;
      compra.Nombre = this.selectProdu.Descripcion;
      compra.Entrada = parseInt(this.cantidadAgregarReposicion);
      compra.Fecha = this.setDateNow().toISOString().substring(0, 10);
      compra.Rutpro = this.selectProveedor.Rut;
      compra.Nomprov = this.selectProveedor.Razon_social;
      compra.Tipo = this.selectTipodocCompra;
      compra.Vencimiento = this.setDateNow().toISOString().substring(0, 10);
      compra.Familia = this.selectProdu.Ubicacion;

      compra.Sucursal = this.user.Sucursal;
      compra.Fechatributacion = this.setDateNow()
        .toISOString()
        .substring(0, 10);
      this.selectCompras.push(compra);
      this.selectProdu = "";
      this.selectCostoCompra = "0";
      this.selectPvpCompra = "0";
      this.cantidadAgregarReposicion = "0";
    },
    btnDeleteCompraByIndex(index) {
      this.selectCompras.splice(index, 1);
    },
    btnDeleteOCByIndex(index) {
      this.selectOcs.splice(index, 1);
    },
    btnCreateReposicionCompra() {
      this.activeLoadingCreateReposicionCompras = true;
      if (!this.selectTipodocCompra) {
        alert("Debe seleccionar un tipo de documento");
        return;
      }
      if (!this.selectNdocCompra) {
        alert("Debe seleccionar un numero de documento");
        return;
      }
      if (!this.selectProveedor.Id) {
        alert("Debe seleccionar un proveedor");
        return;
      }
      if (this.selectCompras.length < 1) {
        alert("No ha ingresado ningun producto a la reposición");
        return;
      }

      this.postReposicionCompras({ compras: this.selectCompras });
    },
    btnAddProduOc() {
      if (!this.selectProduOc.Id) {
        alert("No hay ningun producto seleccionado");
        return;
      }
      if (!this.selectFormaPagoOC) {
        alert("Debe seleccionar la forma de pago");
        return;
      }
      if (!this.selectPlazoOC) {
        alert("Debe seleccionar el plazo de pago");
        return;
      }
      if (!this.selectProveedorOc.Id) {
        alert("Debe seleccionar un proveedor");
        return;
      }
      let oc = {
        Id: 0,
        Rut: "",
        Nombre: "",
        Formapago: "",
        Plazo: "",
        Fecha: "",
        Codigo: "",
        Descripcion: "",
        Cantidad: 0,
        Precio: 0,
        Descuento: 0,
        Subtotal: 0,
        Impuesto: 0,
        Total: 0,
        Hora: "00:00:00",
        Vencimiento: "",
        Numero: 0,
        Sucursal: "",
      };

      oc.Plazo = this.selectPlazoOC;
      oc.Formapago = this.selectFormaPagoOC;
      oc.Nombre = this.selectProveedorOc.Razon_social;
      oc.Rut = this.selectProveedorOc.Rut;
      oc.Descripcion = this.selectProduOc.Descripcion;
      oc.Codigo = this.selectProduOc.Codigo;
      oc.Cantidad = parseInt(this.selectCantidadProduOc);
      oc.Descuento = parseInt(this.porcentajeProudOC);
      oc.Precio = parseInt(this.selectProduOc.Costo);
      oc.Fecha = this.selectFechaOC;
      oc.Vencimiento = this.selectFechaVencimientoOC;
      if (this.porcentajeProudOC != "0") {
        let subtotal =
          parseInt(this.selectCantidadProduOc) * this.selectProduOc.Costo -
          parseInt(this.selectCantidadProduOc) *
            this.selectProduOc.Costo *
            (parseInt(this.porcentajeProudOC) / 100);
        let iva = Math.round(subtotal * 1.19) - subtotal;
        oc.Impuesto = iva;
        oc.Subtotal = subtotal;
        oc.Total = subtotal + iva;
      } else {
        oc.Subtotal =
          parseInt(this.selectCantidadProduOc) *
          parseInt(this.selectProduOc.Costo);
        oc.Impuesto = Math.round(
          parseInt(this.selectCantidadProduOc) *
            parseInt(this.selectProduOc.Costo) *
            0.19
        );
        oc.Total = oc.Subtotal + oc.Impuesto;
      }

      oc.Sucursal = this.user.Sucursal;
      this.selectOcs.push(oc);
      this.selectProduOc = "";
      this.porcentajeProudOC = "0";
      this.selectCantidadProduOc = "1";
    },
    async btnCreateOc() {
      if (this.selectOcs.length == 0) {
        alert("No hay ningun producto en la oc");
        return;
      }
      this.activeLoadingCreteOc = true;
      let result = await this.postOcs({ oc: this.selectOcs });
      if (result != 0) {
        this.activeDialogCreateOc = true;
        this.selectOcs.forEach((element) => {
          element.Numero = result;
        });
      }
    },
    btnCloseDialogOc() {
      this.activeDialogCreateOc = false;
      this.selectOcs = [];
      this.selectFechaOC = "";
      this.selectFechaVencimientoOC = "";
      this.selectProduOc = "";
      this.selectProveedorOc = "";
      this.selectFormaPagoOC = "";
      this.selectPlazoOC = "";
    },
    async btnBuscarOcByFilter() {
      if (this.selectTipoFiltroSearchOc == "fecha") {
        if (!this.selectStartDateSearchOC || !this.selectEndDateSearchOC) {
          alert("Debe ingresar ambas fecha de busqueda");
          return;
        }
      }
      if (this.selectTipoFiltroSearchOc == "ndoc") {
        if (!this.selectNumeroSerachOc) {
          alert("Debe ingresar el numero de oc");
          return;
        }
      }
      if (this.selectTipoFiltroSearchOc == "proveedor") {
        if (!this.selectProveedorOcSearch) {
          alert("Debe seleccionar al proveedor");
          return;
        }
      }
      let result = await this.getOcsByFilter({
        numero: this.selectNumeroSerachOc,
        start_date: this.selectStartDateSearchOC,
        end_date: this.selectEndDateSearchOC,
        proveedor: this.selectProveedorOcSearch.Rut,
        filter: this.selectTipoFiltroSearchOc,
      });
      let setSearchOcs = [];
      if (result.length > 0) {
        result.forEach((element) => {
          let searchIndex = -1;
          searchIndex = setSearchOcs.findIndex(
            (item) => item.Numero == element.Numero
          );
          if (searchIndex == -1) {
            setSearchOcs.push({
              Numero: element.Numero,
              Fecha: element.Fecha,
              Nombre: element.Nombre,
            });
          }
        });
      }

      this.selectOcsSearch = result;
      this.selectOcsSearchSet = setSearchOcs;
    },
    btnSelectOcSearch(numero) {
      this.selectOcs = this.selectOcsSearch.filter(
        (item) => item.Numero == numero
      );
    },
    btnSelectOcSearchReposicion(numero) {
      this.selectOcsReposicion = this.selectOcsSearch.filter(
        (item) => item.Numero == numero
      );
      this.activeDialogCargarProduOcReposicion = true;
    },
    btnImprimirOcSearch() {
      this.activeDialogCreateOc = true;
    },
    btnCancelarCargaOCReposicion() {
      this.selectOcsReposicion = [];
      this.activeDialogCargarProduOcReposicion = false;
    },
    btnCargarOCReposicion() {
      let setCompras = [];
      let fecha = "";
      let numero = "";
      let proveedorSearchRut = "";
      let proveedorSearch = "";
      this.selectOcsReposicion.forEach((element) => {
        let produSearch = this.produs.find(
          (item) => item.Codigo == element.Codigo
        );
        let compra = {
          Guia: "",
          Cod: "",
          Nombre: "",
          Entrada: 0,
          Salida: 0,
          Glosa: "",
          Fecha: "",
          Reg: "0",
          Rutpro: "",
          Nomprov: "",
          Des: 0,
          Neto: 0,
          Iva: 0,
          Total: 0,
          Tipo: "",
          Tipodte: 0,
          Cuenta: "0",
          Vencimiento: "",
          Id: 0,
          Sucursal: "",
          Ivacomun: 0,
          Fechatributacion: "",
          Familia: "n",
        };
        compra.Cod = element.Codigo;
        compra.Guia = element.Numero;
        compra.Nombre = element.Descripcion;
        compra.Entrada = parseInt(element.Cantidad);
        compra.Fecha = this.setDateNow().toISOString().substring(0, 10);
        compra.Rutpro = element.Rut;
        compra.Nomprov = element.Nombre;
        compra.Tipo = "Orden de compra";
        compra.Vencimiento = this.setDateNow().toISOString().substring(0, 10);
        if (produSearch.Id) {
          compra.Familia = produSearch.Ubicacion;
        }
        compra.Iva = element.Impuesto;
        compra.Neto = element.Subtotal;
        compra.Total = element.Total;
        compra.Sucursal = this.user.Sucursal;
        compra.Fechatributacion = this.setDateNow()
          .toISOString()
          .substring(0, 10);
        fecha = element.Fecha;
        numero = element.Numero;
        proveedorSearchRut = element.Rut;

        setCompras.push(compra);
      });
      proveedorSearch = this.proveedores.find(
        (item) => item.Rut == proveedorSearchRut
      );
      this.selectProveedor = proveedorSearch;
      this.selectTipodocCompra = "Orden de compra";
      this.selectFechaCompra = fecha;
      this.selectNdocCompra = numero;
      this.selectCompras = setCompras;
      this.activeDialogCargarProduOcReposicion = false;
      this.activeSearchOcReposicion = false;
    },
    btnSearchProductByServiece() {
      if (!this.searchProdus) {
        alert("Debe ingresar un parametro de busqueda")
        return
      }
      this.getProdusByNameOrCode({ search: this.searchProdus })
    },
    btnExportExcelInventario: function () {
      let set_sheet = [];
      this.inventariosFilter.forEach((element) => {
        let setSheet = {};
        setSheet.Cod = element.Cod;
        setSheet.Nombre = element.Nombre;
        setSheet.Saldo = element.Saldos;
        setSheet.Nuevosaldo = 0;
        setSheet.Estado = "Pendiennte";
        set_sheet.push(setSheet);
      });
      let data = XLSX.utils.json_to_sheet(set_sheet, {
        header: ["Cod", "Nombre", "Saldo", "Nuevosaldo", "Estado"],
      });
      data["A1"].v = "Codigo";
      data["B1"].v = "Producto";
      data["C1"].v = "Cantidad actual";
      data["D1"].v = "Nueva cantidad";
      data["E1"].v = "Estado";

      const workbook = XLSX.utils.book_new();
      let today_text = new Date().toISOString().substring(0, 10);
      const filename = "inventario" + today_text;
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    processExcel(data) {
      var workbook = XLSX.read(data, {
        type: "binary",
      });

      var firstSheet = workbook.SheetNames[0];
      var data = this.to_json(workbook);
      return data;
    },
    to_json(workbook) {
      var result = {};
      workbook.SheetNames.forEach(function (sheetName) {
        var roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
          header: 1,
        });
        if (roa.length) result[sheetName] = roa;
      });
      return JSON.stringify(result, 2, 2);
    },
    importFile() {
      this.inventariosImport = [];
      this.statsInventariosImport.errores = [];
      this.statsInventariosImport.valido = 0;
      this.statsInventariosImport.invalido = 0;
      this.statsInventariosImport.total = 0;
      this.files.forEach((file, f) => {
        this.readers[f] = new FileReader();
        this.readers[f].onload = (e) => {
          var contents = this.processExcel(this.readers[f].result);
          let dataSet = JSON.parse(contents);
          let setArray = dataSet[Object.keys(dataSet)[0]];
          this.dataDocs = setArray.filter((element, index) => index > 0);
          this.dataDocs.forEach((item) => {
            let inventari_set = {
              Cod: "",
              Nombre: "",
              Saldos: 0,
              Cantidad: 0,
              Estado: "",
            };

            if (item[0]) {
              if (typeof item[0] === "int") {
                inventari_set.Cod = item[0].toString();
              } else {
                inventari_set.Cod = item[0];
              }
            }
            if (item[1]) {
              if (typeof item[1] === "int") {
                inventari_set.Nombre = item[1].toString();
              } else {
                inventari_set.Nombre = item[1];
              }
            }
            if (item[2]) {
              if (typeof item[2] === "string") {
                inventari_set.Saldos = parseInt(item[2]);
              } else {
                inventari_set.Saldos = item[2];
              }
            }
            if (item[3]) {
              if (typeof item[3] === "string") {
                inventari_set.Cantidad = parseInt(item[3]);
              } else {
                inventari_set.Cantidad = item[3];
              }
            }
            let find = this.validateCodProdu(inventari_set.Cod);
            if (inventari_set.Cantidad < 0) {
              inventari_set.Estado = "Invalido";
              this.statsInventariosImport.errores.push({
                codigo: inventari_set.Codigo,
                error: "Cantidad menor que 0",
              });
            } else {
              inventari_set.Estado = "Valido";
            }
            if (find == -1) {
              inventari_set.Estado = "Invalido";
              this.statsInventariosImport.errores.push({
                codigo: inventari_set.Codigo,
                error: "Código inexistente",
              });
            } else {
              inventari_set.Estado = "Valido";
            }
            if (inventari_set.Estado == "Valido") {
              this.statsInventariosImport.valido += 1;
            } else {
              this.statsInventariosImport.invalido += 1;
            }
            this.statsInventariosImport.total += 1;
            this.inventariosImport.push(inventari_set);
          });
          // send to server here...
        };

        this.readers[f].readAsBinaryString(this.files[f]);
      });
    },
    validateCodProdu(cod) {
      let index = -1;
      index = this.inventarios.findIndex((item) => item.Cod == cod);
      return index;
    },
    btnActualizarPorArchivo() {
      if (this.inventariosImport.length == 0) {
        alert("No hay productos para importar");
        return;
      }
      this.activeLoadingCreateImportInventario = true;
      let setinventarios = [];
      this.inventariosImport.forEach((element) => {
        if (element.Estado == "Valido") {
          let inventario = this.inventarios.find(
            (item) => item.Cod == element.Cod
          );
          inventario.Ingresos = element.Cantidad;
          inventario.Ventas = 0;
          inventario.Saldos = element.Cantidad;
          //console.log("traido", inventario);
          setinventarios.push(inventario);
        }
      });
      this.postActualizacionTomaInventario({
        inventarios: setinventarios,
        sucursal: this.user.Sucursal,
      });
    },
    btnActualizarIndividual() {
      if (!this.selectProduNuevaCantidaTomaInventario) {
        alert("Debe especificar la nueva cantidad");
        return;
      }
      this.activeLoadingCreateImportInventario = true;
      let setinventarios = [];
      this.selectProduTomaInventario.Saldos = parseInt(
        this.selectProduNuevaCantidaTomaInventario
      );
      this.selectProduTomaInventario.Ingresos =
        this.selectProduTomaInventario.Saldos;
      this.selectProduTomaInventario.Ventas = 0;
      setinventarios[0] = this.selectProduTomaInventario;

      this.postActualizacionTomaInventarioIndividual({
        inventarios: setinventarios,
        sucursal: this.user.Sucursal,
      });
    },
    setCodeescaner(code) {},
    btnGenerarCodigoBarra() {
      this.cantidadCodigoGenerar = parseInt(this.cantidadCodigoGenerar);
      // this.generateBarcodeOptions.height = parseInt(this.altoCodigoGenerar);
      this.activeShowGenerarCodigo = true;
    },
  },
  created() {
    this.selectSucursal = this.user.Sucursal;
    this.setProdus();
    if (this.inventarios.length > 0) {
      this.setStatsInventarios();
    }
    this.setInventarios();
    window.addEventListener("resize", this.checkMobile); // Escucha los cambios de tamaño de ventana
    this.checkMobile(); // Verifica el estado inicial
  },
};
</script>
<style scoped>
.border-edit >>> fieldset {
  border-style: dashed;
}
</style>
