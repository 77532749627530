<template>
  <div>
    <!-- Documento a imprimir -->
    <v-btn
      class="mx-2"
      color="primary"
      outlined
      @click="btnGeneratePdfFileVtaser"
    >
      <div class="d-flex">
        <v-icon>mdi-file-pdf-box</v-icon>
        <div class="mt-1 ml-1 text-capitalize rounded-lg">Descargar pdf</div>
      </div>
    </v-btn>
    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="989999"
        filename="oc"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="letter"
        pdf-orientation="portrait"
        @progress="onProgress($event)"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="generateOcPdf"
      >
        <section slot="pdf-content">
          <div style="padding:10px">
            <h3 class="text-center">Orden de compra N° {{ocs[0].Numero}}</h3>
            <div>
              <div>{{ clinica.Nombre }}</div>
              <div>{{ clinica.Direccion }} {{ clinica.Ciudad }}</div>
              <div>{{ clinica.Fono }}</div>
              <div>Sucursal: {{ user.Sucursal }}</div>
              <div>Proveedor: {{ ocs[0].Nombre}} - Rut: {{ocs[0].Rut}}</div>

              <div>Fecha OC: {{ setDateShortString(ocs[0].Fecha) }} - Vencimiento {{ setDateShortString(ocs[0].Vencimiento) }}  </div>
              <div>Método de pago: {{ ocs[0].Formapago }} - Plazo: {{ ocs[0].Plazo }}  </div>
              
              <div>
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </div>
              <div class="content-title-vta">
                <div class=" content-title-oc-produ">Producto</div>
                <div class="  content-title-oc-codigo">Costo</div>
                <div class=" content-title-oc-codigo">Cantidad</div>
                <div class="content-title-oc-codigo">Descuento %</div>
                <div class=" content-title-oc-codigo">
                  Subtotal
                </div>
                <div class="content-title-oc-codigo">IVA</div>
                <div class="content-title-oc-codigo">
                  Total
                </div>
              </div>
              <div>
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </div>
              <div
                class="content-title-vta"
                v-for="oc in ocs"
                :key="oc.Id"
              >

                <div class="content-title-oc-produ">{{ oc.Descripcion }}</div>
                <div class="content-title-oc-codigo">
                      $ {{ oc.Precio | formatCurrency }}
                </div>
                <div class="content-title-oc-codigo">
                  {{ oc.Cantidad }}
                </div>
                <div class="content-title-oc-codigo">{{ oc.Descuento }} %</div>
                <div class="content-title-oc-codigo">
                      $ {{ oc.Subtotal | formatCurrency }}
                </div>
                <div class="content-title-oc-codigo">
                  $ {{ oc.Impuesto | formatCurrency }}
                </div>
                <div class="content-title-oc-codigo">
                  $ {{ oc.Total | formatCurrency }}
                </div>
               
              </div>
              <div>
                -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </div>
              <div class="total-vta">
                <div>Impuesto $ {{ calculateImpuesto | formatCurrency }}</div>
                <div>
                  Total $ {{ calculateTotal | formatCurrency }}
                </div>
        
              </div>
            </div>
          </div>
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "generateOcPdf",
  components: {
    VueHtml2pdf,
  },
  props: {
    ocs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      today: new Date().toISOString(),
    };
  },
  methods: {
    setDateShortString(date) {
      var dataSet = new Date(date.substring(0, 10) + "T00:00:00");
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " de " + year;
    },
    btnGeneratePdfFileVtaser() {
      this.$refs.generateOcPdf.generatePdf();
    },
    setDateString(date) {
      var dataSet = new Date(date);
      dataSet = this.setDateNow(dataSet);
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year + " a las " + h + ":" + m;
    },
    setDateStringPrint(date) {
      var dataSet = new Date(date);
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return (
        day +
        " de " +
        montTex +
        " del " +
        year +
        " a las " +
        h +
        ":" +
        m +
        " horas"
      );
    },
    setDateStringShortPrint(date) {
      var dataSet = new Date(date + "T00:00:00");
      dataSet = dataSet;
      var day = dataSet.getDate();
      var year = dataSet.getFullYear();
      var month = dataSet.getMonth();
      var montTex = "";
      var h = dataSet.getHours();
      var m = dataSet.getMinutes();
      switch (month) {
        case 0:
          montTex = "Enero";
          break;
        case 1:
          montTex = "Febrero";
          break;
        case 2:
          montTex = "Marzo";
          break;
        case 3:
          montTex = "Abril";
          break;
        case 4:
          montTex = "Mayo";
          break;
        case 5:
          montTex = "Junio";
          break;
        case 6:
          montTex = "Julio";
          break;
        case 7:
          montTex = "Agosto";
          break;
        case 8:
          montTex = "Septiembre";
          break;
        case 9:
          montTex = "Octubre";
          break;
        case 10:
          montTex = "Noviembre";
          break;
        case 11:
          montTex = "Diciembre";
        default:
          break;
      }
      if (m == 0) {
        m = "00";
      }
      if (h == 0) {
        h = "00";
      }
      return day + " de " + montTex + " del " + year;
    },
    setDateNow(date) {
      var currentDateObj = date;
      var numberOfMlSeconds = currentDateObj.getTime();
      var addMlSeconds = 60 * 180000;
      var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
      return newDateObj;
    },
   
  },
  computed: {
    ...mapState("sales-module", ["vtasser"]),
    ...mapState("auth-module", ["clinica", "user"]),
    calculateTotal() {
      let total = 0;
      this.ocs.forEach((element) => {
        total += element.Total;
      });
      return total;
    },
    calculateImpuesto() {
      let total = 0;
      this.ocs.forEach((element) => {
        total += element.Impuesto;
      });
      return total;
    },

  },
  created() {},
};
</script>
<style>
/* Contenido receta a4 */
strong {
  font-weight: 800;
  letter-spacing: 2px;
}
.body-vtaser {
  padding: 10px;
}
.text-center {
  text-align: center;
}
.content-title-vta {
  display: flex;
  width: 100%;
}


.content-title-oc-produ {
  width: 20%;
}
.content-title-oc-codigo {
  width: 12%;
}
</style>